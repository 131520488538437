// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import { RegistriesIdEnum, RegistriesNameEnum } from '../../enums/Registries';

const DE = () => {
  const url: string = '/domains/addBulkCommon';
  return (
    <MainCard title={RegistriesNameEnum.DENIC}>
      <AddDomainsContent
        registryId={RegistriesIdEnum.DENIC}
        registryName={RegistriesNameEnum.DENIC}
        url={url}
        isDatePickerVisible={false}
        config="empty"
      />
    </MainCard>
  );
};

export default DE;
