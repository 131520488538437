// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import { RegistriesIdEnum, RegistriesNameEnum } from '../../enums/Registries';

const CentralNIC = () => {
  const url: string = '/domains/addBulkCommon';
  return (
    <MainCard title={RegistriesNameEnum.CentralNIC}>
      <AddDomainsContent
        registryId={RegistriesIdEnum.CentralNIC}
        registryName={RegistriesNameEnum.CentralNIC}
        url={url}
        isDatePickerVisible={false}
        config={'highPriority'}
      />
    </MainCard>
  );
};

export default CentralNIC;
