import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const IdentityDigitalNDZ = () => {
    //add column ids that need to show the table
    const visibleColumns: string[] = [
        'name',
        'dropTime',
        'drop-date',
        'sentAtEpoch',
        'createDelay',
        'roundTrip',
        'successAttempt',
        'worker',
        'drop-time-identityDigital',
        'status',
        'registeredTo',
        'success-inform'
    ];

    return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.IdentityDigitalNDZ} />;
};

export default IdentityDigitalNDZ;
