// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import {RegistriesIdEnum, RegistriesNameEnum} from "../../enums/Registries";

const Verisign = () => {
  const url: string = '/domains/addBulkDomains';
  return (
    <MainCard title={RegistriesNameEnum.Verisign}>
      <AddDomainsContent registryId = {RegistriesIdEnum.Verisign} registryName={RegistriesNameEnum.Verisign} url={url} isDatePickerVisible={true} />
    </MainCard>
  );
};

export default Verisign;
