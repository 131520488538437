// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import {RegistriesIdEnum, RegistriesNameEnum} from "../../enums/Registries";
const Nominet = () => {
  const url: string = '/domains/addBulkDomains';

  return (
    <MainCard title={RegistriesNameEnum.Nominet}>
      <AddDomainsContent registryId = {RegistriesIdEnum.Nominet} registryName={RegistriesNameEnum.Nominet} url={url} isDatePickerVisible={false}/>
    </MainCard>
  );
};

export default Nominet;
