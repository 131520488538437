// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import {RegistriesIdEnum, RegistriesNameEnum} from '../../enums/Registries';

const SIDN = () => {
  const url: string = '/domains/addBulkDomains';
  return (
    <MainCard title={RegistriesNameEnum.SIDN}>
      <AddDomainsContent registryId = {RegistriesIdEnum.SIDN} registryName={RegistriesNameEnum.SIDN} url={url} isDatePickerVisible={false}/>
    </MainCard>
  );
};

export default SIDN;
