import React, { createContext, useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { getToken } from '../utils/common';
import { openSnackbar } from '../store/reducers/snackbar';
import { useDispatch } from 'react-redux';

interface DataContextProps {
  fetchData?: (url: string) => {};
  postData?: (url: string, body: any) => {};
}

export const DataContext = createContext<DataContextProps>({
  // @ts-ignore
  fetchData: (url: string) => {},
  // @ts-ignore
  postData: (url: string, body: any) => {}
});

export const DataProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  let serviceToken: any;
  useEffect(() => {
    serviceToken = getToken();
  });
  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: 'Sorry, There was a issue on logout',
          variant: 'alert',
          alert: { color: 'error' },
          autoHideDuration: 5000,
          close: true
        })
      );
    }
  };
  const fetchData = async (url: string) => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND + url, {
        headers: {
          Authorization: `Bearer ${serviceToken}`
        }
      });
      return response.data;
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: 'Server Error',
          variant: 'alert',
          alert: { color: 'error' },
          autoHideDuration: 5000,
          close: true
        })
      );
      if (error) {
        // @ts-ignore
        if (error.response.status === 401) {
          await handleLogout();
        }
      }
    }
  };

  const postData = async (url: string, body: any) => {
    serviceToken = await getToken();
    let errorMessage = 'Server Error';
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND + url, body, {
        headers: {
          Authorization: `Bearer ${serviceToken}`
        }
      });
      return response;
    } catch (error: any) {
      if (error.response) {
        errorMessage = error.response?.data?.message ?? errorMessage;
        if (error.response.status === 401) {
          await handleLogout();
        }
      }
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          message: errorMessage,
          variant: 'alert',
          alert: { color: 'error' },
          autoHideDuration: 5000,
          close: true
        })
      );
      return null;
    }
  };

  return <DataContext.Provider value={{ fetchData, postData }}>{children}</DataContext.Provider>;
};
