// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import { DataProvider } from './contexts/DataContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import React from "react";

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

// @ts-ignore
const App = () => (
  <ThemeCustomization>
    {/* <RTLLayout> */}
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <DataProvider>
            <React.Fragment>
              <Routes />
              <Snackbar />
            </React.Fragment>
          </DataProvider>
        </AuthProvider>
      </ScrollTop>
    </Locales>
    {/* </RTLLayout> */}
  </ThemeCustomization>
);

export default App;
