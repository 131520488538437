import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import {RegistryEnum} from "../../../enums/Common";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(option: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(option) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

type RegistryFilterProps = {
  handleOnRegistryChange: (value: string[]) => void;
};
const RegistryFilter = (props: RegistryFilterProps) => {
  const theme = useTheme();
  const [registry, setRegistry] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof registry>) => {
    let {
      target: { value }
    } = event;
    setRegistry(typeof value === 'string' ? value.split(',') : value);
    // if (value.length === 0) {
    //   value = Object.keys(registryEnum).toString().split(',');
    // }
    props.handleOnRegistryChange(typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-chip-label">Registry</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={registry}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Registry" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value.replace(/([A-Z])/g, ' $1')} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {Object.keys(RegistryEnum).map((option: string) => (
  // @ts-ignore
          <MenuItem key={option} value={RegistryEnum[option]} style={getStyles(option, registry, theme)}>
            {option.replace(/([A-Z])/g, ' $1')}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RegistryFilter;
