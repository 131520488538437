import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const Verisign = () => {
  //add column ids that need to show the table
  const visibleColumns: string[] = [
    'name',
    'dropTime',
    'drop-date',
    'create-time',
    'createCount',
    'status',
    'attempt',
    'registeredTo',
    'success-inform'
  ];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.Verisign} />;
};

export default Verisign;
