import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  Grid,
  Snackbar,
  Stack,
  Typography
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { DataContext } from '../../contexts/DataContext';
import AnimateButton from '../@extended/AnimateButton';
import CircularProgress from '@mui/material/CircularProgress';

type SuccessInformPopup = {
  isSuccessInformPopupOpen: boolean;
  domain: string;
  handleInformedSuccessfully: () => void;
  handleInformedFailed: () => void;
  handlePopupClose: () => void;
};

const SuccessInformPopup = (props: SuccessInformPopup) => {
  const [isSuccessSnackBarOpen, setIsSuccessSnackBarOpen] = useState<boolean>(false);
  const [isErrorSnackBarOpen, setIsErrorSnackBarOpen] = useState<boolean>(false);
  const { postData } = useContext(DataContext);
  const [isLoading, setLoading] = useState(false);

  function handleClose() {
    props.handlePopupClose();
  }

  const handleSuccessInform = async () => {
    try {
      if (postData) {
        setLoading(true);
        const result: any = await postData('/domains/manuallyInformSuccessDomain', { domain: props.domain });
        setLoading(false);
        if (result.data.success === true) {
          setIsSuccessSnackBarOpen(true);
          props.handleInformedSuccessfully();
        } else {
          setIsErrorSnackBarOpen(true);
          props.handleInformedFailed();
        }
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  function handleOnOpenSnackBarClose() {
    props.handlePopupClose();
    setIsSuccessSnackBarOpen(false);
    setIsErrorSnackBarOpen(false);
  }

  return (
    <Fragment>
      <Dialog open={props.isSuccessInformPopupOpen} onClose={handleClose} maxWidth="xs">
        <Card>
          <CardContent>
            <Typography variant="h6" color="text.secondary">
              Are you sure to re-submit for the response?
            </Typography>
            <Grid item xs={12} pt={3}>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <AnimateButton>
                  <Button
                      variant="contained"
                      type="submit"
                      onClick={handleSuccessInform}
                      disabled={isLoading}
                  >
                    {isLoading ? (
                        <CircularProgress size={20} color="inherit" />
                    ) : (
                        'Yes'
                    )}
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button variant="outlined" type="submit" color="error" onClick={handleClose}>
                    No
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </CardContent>
        </Card>
      </Dialog>
      <Snackbar
        open={isSuccessSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="success" sx={{ width: '100%' }}>
          Namerider/get response informed as success
        </Alert>
      </Snackbar>
      <Snackbar
        open={isErrorSnackBarOpen}
        autoHideDuration={6000}
        onClose={handleOnOpenSnackBarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleOnOpenSnackBarClose} severity="error" sx={{ width: '100%' }}>
          Namerider/get the response informed as failed, Please retry later
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SuccessInformPopup;
