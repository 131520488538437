export enum RegistriesIdEnum {
  CentralNIC = 'centralnic',
  Verisign = 'verisign',
  SIDN = 'nl',
  IdentityDigital = 'donuts',
  IdentityDigitalNDZ = 'donutsndz',
  RoTLD = 'rotld',
  Nominet = 'uk',
  GoDaddy = 'godaddy',
  ZDNS = 'zdns',
  EURid = 'eu',
  PIR = 'pir',
  DENIC = 'de'
}

export enum RegistriesNameEnum {
  CentralNIC = 'CentralNic',
  Verisign = 'Verisign',
  SIDN = 'SIDN',
  IdentityDigital = 'Identity Digital',
  IdentityDigitalNDZ = 'Identity Digital NDZ',
  RoTLD = 'RoTLD',
  Nominet = 'Nominet',
  GoDaddy = 'GoDaddy',
  ZDNS = 'ZDNS',
  EURid = 'EURid',
  PIR = 'PIR',
  DENIC = 'DENIC'
}
