import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import {RegistriesIdEnum} from "../../enums/Registries";

const GoDaddy = () => {
    //add column ids that need to show the table
    const visibleColumns: string[] = [
        'name',
        'dropTime',
        'drop-date',
        'sentAtEpoch',
        'createDelay',
        'roundTrip',
        'successAttempt',
        'worker',
        'droppedAt',
        'status',
        'registeredTo',
        'success-inform'
    ];

    return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.GoDaddy} />;
};

export default GoDaddy;