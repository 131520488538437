// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import { RegistriesIdEnum, RegistriesNameEnum } from '../../enums/Registries';

const ZDNS = () => {
  const url: string = '/domains/addBulkCommon';
  return (
    <MainCard title={RegistriesNameEnum.ZDNS}>
      <AddDomainsContent
        registryId={RegistriesIdEnum.ZDNS}
        registryName={RegistriesNameEnum.ZDNS}
        url={url}
        isDatePickerVisible={false}
        config={'empty'}
      />
    </MainCard>
  );
};

export default ZDNS;
