// project import
import MainCard from 'components/MainCard';
import AddDomainsContent from '../../sections/add-domains/AddDomainsContent';
import {RegistriesIdEnum, RegistriesNameEnum} from '../../enums/Registries';

const RoTLD = () => {
  const url: string = '/domains/addBulkDomains';
  return (
    <MainCard title={RegistriesNameEnum.RoTLD}>
      <AddDomainsContent registryId = {RegistriesIdEnum.RoTLD} registryName={RegistriesNameEnum.RoTLD} url={url} isDatePickerVisible={false} />
    </MainCard>
  );
};

export default RoTLD;
