import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const DE = () => {
  //add column ids that need to show the table
  const visibleColumns: string[] = ['name', 'dropTime', 'droppedAt', 'status', 'registeredTo', 'success-inform'];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.DENIC} />;
};

export default DE;
