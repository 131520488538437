import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import './filters.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
function getStyles(option: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.indexOf(option) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

type FilterComponentProps = {
  filterName:string;
  registryId?: string;
  filterOptions: string[];
  enableFor?: string[];
  handleOnFilterChange: (value: any) => void;
};
const DropDownFilterComponent = (props: FilterComponentProps) => {
  const lable = props.filterName
  const theme = useTheme();
  const [filterValue, setFilterValue] = useState([]);

  const filterOptions =props.filterOptions;
  const disabled:boolean = props.enableFor && props.registryId ? !props.enableFor.includes(props.registryId) : false;

  const handleChange = (event: any) => {
    setFilterValue(event.target.value);
    props.handleOnFilterChange(event.target.value);
  };

  return (
    <FormControl fullWidth disabled={disabled} >
      <InputLabel id="demo-multiple-chip-label">{lable}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={filterValue}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Status" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {filterOptions.map((option: string) => (
          <MenuItem key={option} value={option} style={getStyles(option, filterValue, theme)}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default DropDownFilterComponent;
