import MainCard from "components/MainCard";
import { RegistriesIdEnum, RegistriesNameEnum } from "enums/Registries";
import AddDomainsContent from "sections/add-domains/AddDomainsContent";

const PIR = () => {
    const url: string = '/domains/addBulkCommon';

    return (
        <MainCard title={RegistriesNameEnum.PIR}>
            <AddDomainsContent registryId = {RegistriesIdEnum.PIR} registryName={RegistriesNameEnum.PIR} url={url} isDatePickerVisible={false} config="score"/>
        </MainCard>
    );
};

export default PIR;