import FilteredDomainsContent from '../../sections/filtered-domains/FilteredDomainsContent';
import { RegistriesIdEnum } from '../../enums/Registries';

const RoTLD = () => {
  //add column ids that need to show the table
  const visibleColumns: string[] = [
    'name',
    'dropTime',
    'droppedAt',
    'status',
    'registeredTo',
    'success-inform'
  ];

  return <FilteredDomainsContent visibleColumns={visibleColumns} registryId={RegistriesIdEnum.RoTLD} />;
};

export default RoTLD;
