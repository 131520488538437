// material-ui
import { Button, FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import AnimateButton from '../../components/@extended/AnimateButton';
import { ChangeEvent, useContext, useState } from 'react';
import ResponseTable from '../../components/tables/ResponseTable';
import { AddDomainResponseType } from '../../types/AddDomains';
import { DataContext } from '../../contexts/DataContext';
import { LoadingButton } from '@mui/lab';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTime } from 'luxon';
import { DateEnum } from '../../enums/Common';

type AddDomainsContentProps = {
  registryId: string;
  registryName: string;
  url: string;
  isDatePickerVisible: boolean;
  config?: string;
};
const AddDomainsContent = (props: AddDomainsContentProps) => {
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [isResponseVisible, setIsResponseVisible] = useState<boolean>(false);
  const [domainsList, setDomainsList] = useState<string[]>([]);
  const [invalidDomainsList, setInvalidDomainsList] = useState<AddDomainResponseType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dropDate, setDropDate] = useState<DateTime>(DateTime.now());

  const { postData } = useContext(DataContext);

  const handleOnDomainsListChange = (data: any) => {
    const invalidDomainsListTemp: AddDomainResponseType[] = [];
    const validDomainsListTemp: string[] = [];
    // const domainRegex = new RegExp('^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\\.[^\\.\\.]{2,63})+$');
    const domainRegex = new RegExp('^([^\\.]{1,63})(\\.[^\\.\\.]{2,63})+$');
    let domainNames = data.trim().split(/\n/);
    domainNames.map((domain: string) => {
      domain = domain.trim().toLocaleLowerCase();
      domain = domain.replace(/\s+/g, '');
      if (domainRegex.test(domain)) {
        validDomainsListTemp.push(domain);
      } else {
        if (domain) {
          invalidDomainsListTemp.push({ name: domain, status: 'Invalid domain' });
        }
      }
    });
    setDomainsList(validDomainsListTemp);
    setInvalidDomainsList(invalidDomainsListTemp);
    setIsSubmitDisable(!(validDomainsListTemp.length > 0));
  };

  const handleOnSubmit = async () => {
    setIsLoading(true);
    let response: any;
    let body: any = {};
    if (props.config) {
      body['data'] = domainsList.map((domainName) => {
        let config: any = {};
        switch (props.config) {
          // @ts-ignore
          case 'addedDate':
            // @ts-ignore
            config[props.config] = dropDate.toFormat('yyyy-MM-dd');
            break;
          case 'score':
            let domainData = domainName.trim().split(',');
            config[props.config] = domainData.length > 1 ? (domainData[1] ? parseInt(domainData[1]) : 0) : 0;
            domainName = domainData[0];
            break;
          case 'highPriority':
            // @ts-ignore
            config[props.config] = false;
            break;
          default:
            config = {};
            break;
        }
        return {
          domain: domainName,
          configs: config
        };
      });
    } else {
      body = { domainNames: domainsList };
      if (props.isDatePickerVisible) {
        body['domainDate'] = dropDate.toMillis();
      } else {
        body = { domainNames: domainsList };
      }
      body['service'] = props.registryId;
    }
    console.log(body);
    // try {
    if (postData) {
      response = await postData(props.url, body);
    }
    // } catch (error) {
    //   console.log(error);
    // }
    setIsLoading(false);
    setInvalidDomainsList(invalidDomainsList.concat(response?.data ?? []));
    setIsResponseVisible(true);
  };

  const handleOnCancel = () => {
    setIsResponseVisible(false);
    setIsSubmitDisable(true);
  };

  if (isResponseVisible) {
    return (
      <Grid container>
        <Grid item xs={12} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
          <ResponseTable responseData={invalidDomainsList} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Stack direction="row" justifyContent="flex-end">
            <AnimateButton>
              <Button color="primary" variant="contained" onClick={handleOnCancel}>
                Done
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container>
        {props.isDatePickerVisible && (
          <Grid item mb={2}>
            <InputLabel>Drop Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DesktopDatePicker
                minDate={DateTime.now()}
                value={dropDate}
                // @ts-ignore
                onChange={(event) => setDropDate(event)}
                inputFormat={DateEnum.DateFormat}
                renderInput={(params: any) => <TextField {...params} sx={{ width: '100%' }} />}
              />
              {/*<FormHelperText>select domain list dropping date here</FormHelperText>*/}
            </LocalizationProvider>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputLabel>Domains</InputLabel>
          <TextField
            fullWidth
            minRows={10}
            maxRows={20}
            multiline
            placeholder="Ex: example-domain.tld"
            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleOnDomainsListChange(event.target.value)}
          />
          <FormHelperText>add domains list here</FormHelperText>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <AnimateButton>
              <LoadingButton variant="contained" type="submit" loading={isLoading} disabled={isSubmitDisable} onClick={handleOnSubmit}>
                Submit
              </LoadingButton>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }
};
export default AddDomainsContent;
