import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import TextField from '@mui/material/TextField';
import { DateTime } from 'luxon';
import { Box, Grid, Typography } from '@mui/material';
import {DateEnum} from "../../enums/Common";

type RangePickerProps = {
  startDate: number;
  endDate: number;
  handleOnDateChange: (value: [number | null, number | null]) => void;
  isStartEndDateLimitApplied? :boolean;
};

const RangePicker = (props: RangePickerProps) => {
  const { handleOnDateChange } = props;

  const handleStartDateChange = (date: any) => {
    if (date) {
      date = date.set({ hour: 0, minute: 0, second: 0, milliSecond: 0 }).toMillis();
      let endDate: number;
      if (props.isStartEndDateLimitApplied === false) {
        endDate = date > props.endDate ? date + 2678400000 : props.endDate;
      } else {
        endDate = date + 2678400000 < props.endDate || date > props.endDate ? date + 2678400000 : props.endDate;
      }
      handleOnDateChange([date, endDate]);
    }
  };

  const handleEndDateChange = (date: any) => {
    if (date) {
      date = date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toMillis();
      let startDate: number;
      if (props.isStartEndDateLimitApplied === false) {
        startDate = date < props.startDate ? date - 2678400000 : props.startDate;
      } else {
        startDate = date - 2678400000 > props.startDate || date < props.startDate ? date - 2678400000 : props.startDate;
      }
      handleOnDateChange([startDate, date]);
    }
  };


  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Box>
        <Grid container direction="row">
          <Grid item xs={5}>
            <DesktopDatePicker
              label="Start date"
              value={DateTime.fromMillis(props.startDate)}
              onChange={handleStartDateChange}
              inputFormat={DateEnum.DateFormat}
              renderInput={(params: any) => <TextField {...params} sx={{ width: '100%' }} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography align="center" mt={2}>
              to
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <DesktopDatePicker
              label="End date"
              value={DateTime.fromMillis(props.endDate)}
              onChange={handleEndDateChange}
              inputFormat={DateEnum.DateFormat}
              renderInput={(params: any) => <TextField {...params} sx={{ width: '100%' }} />}
            />
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default RangePicker;
